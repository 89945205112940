import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "photography" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ContentComponent = _resolveComponent("ContentComponent")!
  const _component_ParallaxComponent = _resolveComponent("ParallaxComponent")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_ContentComponent, {
      number: 1,
      content: _ctx.content1
    }, null, 8, ["content"]),
    _createVNode(_component_ParallaxComponent, { imageUrl: _ctx.imageUrl1 }, null, 8, ["imageUrl"]),
    _createVNode(_component_ContentComponent, {
      number: 2,
      content: _ctx.content2
    }, null, 8, ["content"]),
    _createVNode(_component_ParallaxComponent, { imageUrl: _ctx.imageUrl2 }, null, 8, ["imageUrl"]),
    _createVNode(_component_ContentComponent, {
      number: 3,
      content: _ctx.content3
    }, null, 8, ["content"]),
    _createVNode(_component_ParallaxComponent, { imageUrl: _ctx.imageUrl3 }, null, 8, ["imageUrl"]),
    _createVNode(_component_ContentComponent, {
      number: 4,
      content: _ctx.content4
    }, null, 8, ["content"]),
    _createVNode(_component_ParallaxComponent, { imageUrl: _ctx.imageUrl4 }, null, 8, ["imageUrl"]),
    _createVNode(_component_ContentComponent, {
      number: 5,
      content: _ctx.content5
    }, null, 8, ["content"]),
    _createVNode(_component_ParallaxComponent, { imageUrl: _ctx.imageUrl5 }, null, 8, ["imageUrl"]),
    _createVNode(_component_ContentComponent, {
      number: 6,
      content: _ctx.content6
    }, null, 8, ["content"]),
    _createVNode(_component_ParallaxComponent, { imageUrl: _ctx.imageUrl6 }, null, 8, ["imageUrl"])
  ]))
}