import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "trading-bot" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CustomText = _resolveComponent("CustomText")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_CustomText, { tag: "h1" }, {
      default: _withCtx(() => [
        _createTextVNode(" Trading Bot ")
      ]),
      _: 1
    }),
    _createVNode(_component_CustomText, { tag: "h2" }, {
      default: _withCtx(() => [
        _createTextVNode(" Test ")
      ]),
      _: 1
    })
  ]))
}