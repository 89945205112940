import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "projects" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CustomText = _resolveComponent("CustomText")!
  const _component_ProjectComponent = _resolveComponent("ProjectComponent")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_CustomText, { tag: "h1" }, {
      default: _withCtx(() => [
        _createTextVNode(" Projects ")
      ]),
      _: 1
    }),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.projectList, (project) => {
      return (_openBlock(), _createBlock(_component_ProjectComponent, {
        key: project.name,
        name: project.name,
        route: project.route,
        image: project.image,
        description: project.description
      }, null, 8, ["name", "route", "image", "description"]))
    }), 128))
  ]))
}