<template>
    <div class="trading-bot">
      <CustomText tag="h1">
        Trading Bot
      </CustomText>
      <CustomText tag="h2">
        Test
      </CustomText>
      
    </div>
  </template>

<script lang="ts">
import CustomText from '@/components/TextComponent.vue';

export default {
  components : {
    CustomText,
  },
};
</script>
  