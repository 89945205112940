import { renderSlot as _renderSlot, resolveDynamicComponent as _resolveDynamicComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-55df48be"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "content-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.tag), {
      class: _normalizeClass(_ctx.customClass)
    }, {
      default: _withCtx(() => [
        _renderSlot(_ctx.$slots, "default", {}, undefined, true)
      ]),
      _: 3
    }, 8, ["class"]))
  ]))
}