import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "project-component" }
const _hoisted_2 = { class: "project-image" }
const _hoisted_3 = ["src", "alt"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CustomText = _resolveComponent("CustomText")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_CustomText, { tag: "h3" }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.name), 1)
      ]),
      _: 1
    }),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_router_link, { to: _ctx.route }, {
        default: _withCtx(() => [
          _createElementVNode("img", {
            src: _ctx.imagePath,
            alt: _ctx.name
          }, null, 8, _hoisted_3)
        ]),
        _: 1
      }, 8, ["to"])
    ]),
    _createVNode(_component_CustomText, { tag: "p" }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.description), 1)
      ]),
      _: 1
    })
  ]))
}